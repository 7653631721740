function Modal(props) {
  return (
    <div className="ModalPage">
      <div className="ModalContainer">
        <div className="ModalText ">
          {props.data}
        </div>
        <div className="HorizontallyCenteredText ModalButtonContainer">
          <button onClick={props.onClickCloseModal} className="BackgroundColorBlack ColorWhite ModalButton">
            بستن
          </button>

        </div>
      </div>
    </div>
  );
}
export default Modal;
