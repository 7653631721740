import { Route } from "react-router-dom";

import React from "react";

import SearchByFilter from "./pages/SearchByFilter";
import SearchProduct from "./pages/SearchProduct";

// import {
//   BrowserRouter as Router,
//   Route,
//   Routes
// } from 'react-router-dom';
// import SearchByFilter from './pages/SearchByFilter';
// import SearchProduct from "./pages/SearchProduct";

function App() {
  return (
    <div>
      <Route path="/" exact>
        <SearchProduct />
      </Route>

      <Route path="/:filter" exact>
        <SearchByFilter />
      </Route>
      
    </div>
  );
}

export default App;
