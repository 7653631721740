import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

function SearchByFilter() {
  const { filter } = useParams();
  const [inventoryResponse, setInventoryResponse] = useState([]);
  useEffect(() => {
    fetch("https://api.akuvoxiran.ir/api/Inventory/Search/" + filter)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const inventoryResponse = data.resultMessage;
        setInventoryResponse(inventoryResponse);
      });
  });

  return (
    <main>
      <img className="AkuvoxProducts" src="/AkuvoxProducts.png" alt="" />
      <div className="container">
        <img className="PardikLogoCss" src="/Pardik logo.png" alt="" />
        <div className="TextandtitleContainer">
          <div className="gandomFont HeaderText RightToLeft">سامانه استعلام اصالت محصولات Akuvox در ایران </div>
          <div className="TitleText RightToLeft"> {inventoryResponse}</div>
          <div className="BackToPageContainer">
            <NavLink exact className="BackToPage" to="/">
              جستجو کد جدید
            </NavLink>
          </div>
        </div>

        <div>
          &copy; 2021 -{" "}
          <a href="https://khane-hooshmand.ir/" target="_blank">
            Pardik
          </a>
        </div>
      </div>
    </main>
  );
}

export default SearchByFilter;
