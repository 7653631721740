import React, { useState } from "react";
import Modal from "../components/ui/Modal";
import Backdrop from "../components/ui/Backdrop";

function SearchProduct() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryResponse, setInventoryResponse] = useState([]);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = { filter };
    console.log(form.filter);
    fetch("https://api.akuvoxiran.ir/api/Inventory/Search/" + form.filter, {})
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInventoryResponse(data.resultMessage);
        modalOpenerHandler();
        setIsLoading(false);
      });
  };

  function modalOpenerHandler() {
    setModalIsOpen(true);
  }
  function modalCloserHandler() {
    setModalIsOpen(false);
  }
  if (isLoading) {
    return (
      <main>
        <div className="container">
          <img className="PardikLogoCss" src="/Pardik logo.png" alt="" />
          <section>
            <div className="loader"></div>
          </section>
        </div>
      </main>
    );
  }
  return (
    <main>
      <img className="AkuvoxProducts" src="/AkuvoxProducts.png" alt="" />
      <div className="container">
        <img className="PardikLogoCss" src="/Pardik logo.png" alt="" />
        <div className="TextandtitleContainer">
          <div className="gandomFont HeaderText RightToLeft">سامانه استعلام اصالت محصولات Akuvox در ایران </div>
          <div className="TitleText"> سریال یا مک آدرس دستگاه را وارد کنید</div>
          <div className="search-box">
            <form onSubmit={handleSubmit} className="search-form">
              <input className="inputCss" id="search" type="text" placeholder="سریال یا مک آدرس " required value={filter} onChange={(e) => setFilter(e.target.value)} />
              <button className="searchbtn">جستجو</button>
            </form>
          </div>
        </div>
        <div>
          &copy; 2021 -{" "}
          <a href="https://khane-hooshmand.ir/" target="_blank">
            Pardik
          </a>
        </div>
      </div>
      {modalIsOpen && <Modal onClickCloseModal={modalCloserHandler} data={inventoryResponse} />}
      {modalIsOpen && <Backdrop onClickCloseModal={modalCloserHandler} />}
    </main>
  );
}
export default SearchProduct;
